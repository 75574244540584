// mock api
// import './_apis_';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

// default css
import 'styles/index.css';

// i18n
import './utils/locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { BrowserRouter } from 'react-router-dom';

// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { BackendApiProvider } from 'contexts/backend/BackendApiContext';
import { DeepLinkProvider } from 'contexts/deeplink/DeepLinkContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/auth/AuthContext';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
//NOTE: to remove console
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.time = () => {};
  console.debug = () => {};
}
const rootElement = document.getElementById('root');

const ComponentsHierarchy = () => (
  <BackendApiProvider>
    <DeepLinkProvider>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <HelmetProvider>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </HelmetProvider>
            </BrowserRouter>
          </CollapseDrawerProvider>
        </LocalizationProvider>
      </SettingsProvider>
    </DeepLinkProvider>
  </BackendApiProvider>
);

if (rootElement?.hasChildNodes()) {
  hydrate(<ComponentsHierarchy />, rootElement);
} else {
  render(<ComponentsHierarchy />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
