import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthContent = styled(Box)(() => ({
  margin: '0 auto',
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start'
}));

export const Header = styled('header')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100%',
  padding: '16px',
  [theme.breakpoints.up('sm')]: {
    padding: '40px'
  }
}));

export const SmallLogo = styled('img')(({ theme }) => ({
  width: '32px',
  height: '32px',
  [theme.breakpoints.up('sm')]: {
    width: '40px',
    height: '40px'
  }
}));

export const LogoStack = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '60px'
}));

export const LogoImg = styled('img')(({ theme }) => ({
  width: '203.67px',
  height: '32px',
  [theme.breakpoints.up('sm')]: {
    width: '305.5px',
    height: '48px'
  }
}));

export const TextFieldStack = styled(Stack)(({ theme }) => ({
  marginTop: '20px',
  '.MuiTextField-root:first-of-type': {
    paddingBottom: '16px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '24px'
    }
  }
}));

export const AuthRouteStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  margin: '16px 0px',
  a: {
    padding: '6px 8px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#637381',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: 'rgba(145, 158, 171, 0.08)'
    }
  },
  '.AuthRoute-Divider': {
    margin: '0px 8px',
    width: '1px',
    height: '10px',
    borderRight: '1px solid rgba(145, 158, 171, 0.32)',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 24px'
    }
  }
}));

export const SubRouteStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginTop: '16px',
  '& a': {
    fontSize: '14px',
    color: theme.palette.font.gray4
  }
}));
