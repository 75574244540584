// routes
import { PATH_DASHBOARD } from 'routes/paths';

import i18n from 'i18next';
import { DeviceInfo, AdminType } from '@types';

const generateSidebarConfig = (device: DeviceInfo[], isStaff?: AdminType) => {
  const deviceCodeArray = [
    {
      title: 'Overview',
      path: PATH_DASHBOARD.exams.overview
    }
  ];
  const managementArray = [
    // MANAGEMENT : 검사자 계정 설정
    {
      title: i18n.t('UserAccountSetting'),
      path: PATH_DASHBOARD.management.userAccountSetting
    },
    // MANAGEMENT : 대상자 관리
    {
      title: i18n.t('SubjectManagement'),
      path: PATH_DASHBOARD.management.subjectManagement
    },
    // MANAGEMENT : 고객 관리
    {
      title: i18n.t('CustomerService'),
      path: PATH_DASHBOARD.management.customerService
    }
  ];

  const isHandGripDevice =
    device.findIndex((item, index) => item.device.serial_number.substring(4, 6) === 'H1') !== -1;
  const isSitToStandDevice =
    device.findIndex((item, index) => item.device.serial_number.substring(4, 6) === 'C1') !== -1;
  const isGaitSpeedDevice =
    device.findIndex((item, index) => item.device.serial_number.substring(4, 6) === 'G1') !== -1;
  const isStaticBalanceDevice =
    device.findIndex((item, index) => item.device.serial_number.substring(4, 6) === 'B1') !== -1;
  const isAverageGaitSpeedDevice =
    device.findIndex((item, index) => {
      return (
        item.device.serial_number.substring(4, 6) === 'AL' ||
        item.device.serial_number.substring(4, 6) === 'AR'
      );
    }) !== -1;

  if (isSitToStandDevice && isGaitSpeedDevice && isStaticBalanceDevice) {
    deviceCodeArray.push({
      title: i18n.t('SPPB'),
      path: PATH_DASHBOARD.exams.SPPB
    });
  }
  if (isGaitSpeedDevice) {
    deviceCodeArray.push({
      title: i18n.t('GaitSpeedShort'),
      path: PATH_DASHBOARD.exams.GaitSpeed
    });
  }
  if (isSitToStandDevice) {
    deviceCodeArray.push({
      title: i18n.t('SitToStandShort'),
      path: PATH_DASHBOARD.exams.SitToStand
    });
  }
  if (isStaticBalanceDevice) {
    deviceCodeArray.push({
      title: i18n.t('StaticBalanceShort'),
      path: PATH_DASHBOARD.exams.StaticBalance
    });
  }
  if (isAverageGaitSpeedDevice) {
    deviceCodeArray.push({
      // AVG
      title: i18n.t('AverageGaitSpeedShort'),
      path: PATH_DASHBOARD.exams.AverageGaitSpeed
    });
  }
  if (isSitToStandDevice) {
    deviceCodeArray.push({
      title: i18n.t('TUGShort'),
      path: PATH_DASHBOARD.exams.TUG
    });
  }
  if (isStaticBalanceDevice) {
    deviceCodeArray.push({
      title: i18n.t('OneLegStandingShort'),
      path: PATH_DASHBOARD.exams.OneLegStanding
    });
  }
  if (isHandGripDevice) {
    deviceCodeArray.push({
      title: i18n.t('HandgripStrengthShort'),
      path: PATH_DASHBOARD.exams.HandgripStrength
    });
  }

  if (isStaff === 'admin') {
    // MANAGEMENT : 관리자 모드 (Admin Only)
    managementArray.push({
      title: i18n.t('AdminDashboard'),
      path: PATH_DASHBOARD.management.adminDashboard
    });
  }

  const sidebarConfig = [
    // exam
    { subheader: i18n.t('ExamShort'), items: deviceCodeArray },

    // management
    {
      subheader: i18n.t('Management'),
      items: managementArray
    }
  ];

  return sidebarConfig;
};

export default generateSidebarConfig;
