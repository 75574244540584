import React, { createContext, useCallback, useEffect, useReducer } from 'react';

import { ActionMap } from '..';
import { DeepLinkContextType, DeepLinkState } from './DeepLinkContext.type';
import { authCodeRandomGenerator } from 'utils/common.utils';
import useBackendApi from 'hooks/useBackendApi';

const BASE_AUTH_URL = 'localhost:3000/adtf'; //FIXME: 추후 변경 필요
enum Types {
  Set = 'SET'
}

type DeepLinkPayload = {
  [Types.Set]: {
    linkedEmail?: string;
    linkedCode?: string;
    temporaryToken?: string;
  };
};

type DeepLinkActions = ActionMap<DeepLinkPayload>[keyof ActionMap<DeepLinkPayload>];

const initialState: DeepLinkState = {
  linkedEmail: undefined,
  linkedCode: undefined,
  temporaryToken: undefined
};

const reducer = (state: DeepLinkState, action: DeepLinkActions): DeepLinkState => {
  switch (action.type) {
    case Types.Set:
      return {
        ...state,
        linkedEmail: action.payload.linkedEmail,
        linkedCode: action.payload.linkedCode,
        temporaryToken: action.payload.temporaryToken
      };

    default:
      return state;
  }
};

const DeepLinkContext = createContext<DeepLinkContextType | null>(null);

function DeepLinkProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sendAuthLink } = useBackendApi();

  useEffect(() => {
    console.log('[DeepLink] EmailAuthCode =>', state.linkedCode);
  }, [state.linkedCode]);

  const verifyLink = useCallback(
    (code?: string) => {
      if (code && state.linkedCode) {
        return code === state.linkedCode;
      } else {
        return undefined;
      }
    },
    [state.linkedCode]
  );

  const sendDeepLink = useCallback(
    async (email: string, screenName: string) => {
      if (BASE_AUTH_URL) {
        const code = authCodeRandomGenerator();
        const response = await sendAuthLink(email, `${BASE_AUTH_URL}${screenName}/${code}`);

        dispatch({
          type: Types.Set,
          payload: {
            linkedEmail: email,
            linkedCode: code,
            temporaryToken: response.token
          }
        });
      } else {
        throw new Error('[DeepLinkContext/sendDeepLink] Unsupported platform!');
      }
    },
    [sendAuthLink]
  );

  return (
    <DeepLinkContext.Provider
      value={{
        ...state,
        verifyLink: verifyLink,
        sendDeepLink: sendDeepLink
      }}
    >
      {children}
    </DeepLinkContext.Provider>
  );
}

export { DeepLinkContext, DeepLinkProvider };
