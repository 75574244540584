import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import LanguagePopover from './dashboard/LanguagePopover';
import { Stack } from '@mui/material';

const Header = styled('header')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  padding: '16px',
  [theme.breakpoints.up('sm')]: {
    padding: '40px'
  }
}));

const ImgBox = styled('div')(({ theme }) => ({
  width: '32px',
  height: '32px',
  [theme.breakpoints.up('sm')]: {
    width: '40px',
    height: '40px'
  }
}));

export default function AuthHeader() {
  return (
    <Header>
      <Stack
        sx={{ width: '100%' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ImgBox>
          <RouterLink to="/">
            <img src="/static/Logo.svg" alt="full-name-logo" />
          </RouterLink>
        </ImgBox>
        <LanguagePopover />
      </Stack>
      <Outlet />
    </Header>
  );
}
