import { Helmet } from 'react-helmet-async';

const AndanteFitMetaTag = ({ currentLang }: { currentLang: string }): JSX.Element => {
  if (currentLang === 'ko') {
    return (
      <Helmet>
        <title>안단테핏</title>
        {/* <meta property="og:title" content="안단테핏(AndanteFit)" />
        <meta property="og:description" content="가장 간편한 신체기능 검사 솔루션" />
        <meta property="og:url" content="www.andantefit.com" />
        <meta property="og:type" content="website" />
        <meta name="og:site_name" content="AndanteFit" />
        <meta property="og:image" content="%PUBLIC_URL%/static/andantefit_ogimage.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
      </Helmet>
    );
  } else {
    return (
      <Helmet>
        <title>AndanteFit</title>
        {/* <meta property="og:title" content="AndanteFit" />
        <meta property="og:description" content="The smartest physical performance test solution" />
        <meta property="og:url" content="www.andantefit.com" />
        <meta property="og:type" content="website" />
        <meta name="og:site_name" content="AndanteFit" />
        <meta property="og:image" content="%PUBLIC_URL%/static/andantefit_ogimage.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
      </Helmet>
    );
  }
};

export default AndanteFitMetaTag;
