import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';
// pages
import Login from '../pages/authentication/login/Login';
import AndanteFitMetaTag from '../utils/setMetatag';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { currentLang } = useLocales();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    console.log('[AuthGuard] isAuthenticated : ', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    console.log('[AuthGuard]', { pathname, requestedLocation });
  }, [pathname, requestedLocation]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Login />;
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>
      <AndanteFitMetaTag currentLang={currentLang.value} />
      {children}
    </>
  );
}
