import { Button, ButtonProps, styled } from '@mui/material';

interface BaseButtonAttrs {
  buttonsize?: 'sm' | 'md' | 'lg';
}
type BaseButtonProps = BaseButtonAttrs & Omit<ButtonProps, keyof BaseButtonAttrs>;

const BaseButtonStyle = styled(Button)<{ buttonsize?: BaseButtonAttrs['buttonsize'] }>(
  ({ theme, variant, buttonsize }) =>
    variant === 'contained'
      ? {
          minWidth: buttonsize === 'sm' ? '128px' : undefined,
          height: buttonsize === 'sm' ? '36px' : '48px',
          fontSize: buttonsize === 'sm' ? '14px' : '15px',
          fontWeight: 700,
          padding: '6px 14px',
          backgroundColor: '#5486E8',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#3A76E9 !important',
            boxShadow: 'none !important'
          },
          '&:disabled': {
            backgroundColor: '#CBCBCB',
            color: 'white'
          }
        }
      : variant === 'outlined'
      ? {
          minWidth: buttonsize === 'sm' ? '128px' : undefined,
          height: buttonsize === 'sm' ? '36px' : '48px',
          fontSize: buttonsize === 'sm' ? '14px' : '14px',
          fontWeight: 700,
          padding: '6px 14px',
          border: '1px solid #5486E8',
          backgroundColor: 'white',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#EAF1FF !important',
            boxShadow: 'none !important'
          },
          '&:disabled': {
            border: '1px solid #E5E5E5',
            color: theme.palette.font.gray2
          }
        }
      : undefined
);

const BaseButton = (props: BaseButtonProps) => {
  return (
    <BaseButtonStyle
      {...props}
      buttonsize={props.buttonsize}
      fullWidth
      size={props.size || 'large'}
      type={props.type || 'submit'}
      variant={props.variant || 'contained'}
      disabled={props.disabled}
    />
  );
};

export default BaseButton;
