/**
 * [Utility function] Set delay
 * @param {Number} ms Target delay time in millisecond
 * @returns {Promise<void>}
 */

import validator from 'validator';

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const authCodeRandomGenerator = (): string =>
  Math.floor(100000 + Math.random() * 900000).toString();

// 검증 코드 추가
export const isValidDateFormat = (date: string) => {
  // 'YYYY-MM-DD' 형식의 날짜가 올바른지 검증
  return validator.isDate(date, { format: 'YYYY-MM-DD', strictMode: true });
};

export const calculateAge = (birthDay: Date) => {
  /**
   * 산출방법
   *
   * (1) 기준일이 속한 연도에서 출생일이 속한 연도를 뺀다.
   *
   * (2a) 기준일이 생일 전이라면 1세를 뺀다.
   *
   * (2b) 기준일이 생일 이후라면 그 결과를 그대로 쓴다.
   *
   */
  const birthYear = birthDay.getFullYear();
  const birthMonth = birthDay.getMonth();
  const birthDate = birthDay.getDate();

  const todayYear = new Date().getFullYear();
  const todayMonth = new Date().getMonth();
  const todayDate = new Date().getDate();

  // 산출방법: 1
  let age = todayYear - birthYear;
  const monthDiff = todayMonth - birthMonth;

  // 산출방법: 2a
  if (monthDiff < 0 || (monthDiff === 0 && todayDate < birthDate)) {
    return age - 1;
  }

  // 산출방법: 2b
  return age;
};
