import styled from '@emotion/styled';
import { Button, styled as MuiStyled, Typography } from '@mui/material';
import uponMediaSize from 'theme/mediaQuery';

/**
 * @Heading
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: #212b36;
 */
export const Heading = styled('p')<{ fontWeight?: string | number }>`
  font-size: 24px;
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 36px;
  color: ${(props) => props.color || '#212b36'};
  ${uponMediaSize('sm')} {
    font-size: 32px;
    line-height: 48px;
  }
`;

/**
 * @ContentHead
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #212b36;
 */
export const ContentHead = styled('p')<{ fontWeight?: string | number }>`
  font-size: 24px;
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 36px;
  color: ${(props) => props.color || '#212b36'};
`;

/**
 * @Title
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #212b36;
    uponMediaSize(sm) = `font-size: 20px`
 */
export const Title = styled('p')<{ fontWeight?: string | number }>`
  font-size: 20px;
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 36px;
  color: ${(props) => props.color || '#212b36'};
  ${uponMediaSize('sm')} {
    font-size: 24px;
  }
`;

/**
 * @ModalTitle
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #212b36;
 */
export const ModalTitle = styled('p')<{ fontWeight?: string | number }>`
  font-size: 18px;
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 28px;
  color: ${(props) => props.color || '#212b36'};
`;

/**
 * @CardTitle
    font-size: 18px -> {sm: 16px};
    font-weight: 700;
    line-height: 28px;
    color: #212b36;
 */
export const CardTitle = MuiStyled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '28px',
  color: '#212b36',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px'
  }
}));

/**
 * @Subtitle
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    uponMediaSize(sm) = `font-size: 20px`
 */
export const Subtitle = styled('p')<{ fontWeight?: string | number }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color || '#212b36'};
`;

/**
 * @Subtitle1
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    uponMediaSize(sm) = `font-size: 20px`
 */
export const Subtitle1 = styled('p')<{ fontWeight?: string | number }>`
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color || '#212b36'};
`;

/**
 * @BodyText
    font-size: 16px;
    font-weight: props.fontWeight || 400;
    line-height: 24px;
    color:  props.fontColor || #637381;
    white-space: pre-wrap;
 */
export const BodyText = styled('span')<{ fontWeight?: string | number; textAlign?: string }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) => props.color || '#637381'};
  white-space: pre-wrap;
  text-align: ${(props) => props.textAlign || 'left'};
  ${uponMediaSize('sm')} {
    font-size: 16px;
    line-height: 24px;
  }
`;

/**
 * @Body2Text
    font-size: 16px;
    font-weight: props.fontWeight || 400;
    line-height: 24px;
    color:  props.fontColor || #637381;
    white-space: pre-wrap;
 */
export const Body2Text = styled('span')<{ fontWeight?: string | number; textAlign?: string }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) => props.color || '#637381'};
  white-space: pre-wrap;
  text-align: ${(props) => props.textAlign || 'left'};
`;

/**
 * @SubBodyText
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #212b36;
    uponMediaSize(sm) = `font-size: 20px`
 */
export const SubBodyText = styled('span')<{ fontWeight?: string | number }>`
  display: flex;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.color || '#212b36'};
  white-space: pre-wrap;
`;

export const SubBodyText2 = styled('span')<{ fontWeight?: string | number }>`
  display: flex;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.color || '#212b36'};
  white-space: nowrap;
`;

/**
 * @LinkText
    font-size: 14px;
    font-weight: props.fontWeight || 700;
    line-height: 22px;
    color:  props.fontColor || #5486E8;
 */
export const LinkText = styled(Button)<{ fontWeight?: string | number }>`
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 22px;
  color: ${(props) => props.color || '#5486E8'};
  text-transform: none;
  padding: 0;
  &:hover {
    color: #3a76e9;
    background-color: transparent;
  }
`;

/**
 * @LabelText
    font-size: 13px;
    font-weight: props.fontWeight || 500;
    line-height: 20px;
    color:  props.fontColor || #637381;
 */
export const LabelText = styled('p')`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #637381;
`;

/**
 * @Caption
    font-size: 12px;
    font-weight: props.fontWeight || 400;
    line-height: 18px;
    color:  props.fontColor || #637381;
 */
export const Caption = styled('p')<{ fontWeight?: string | number }>`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 18px;
  color: ${(props) => props.color || '#637381'};
`;

/**
 * @ErrorText
    font-size: 12px;
    font-weight: props.fontWeight || 400;
    line-height: 18px;
    color:  props.fontColor || #EB5757;
 */
export const ErrorText = styled('p')<{ fontWeight?: string | number }>`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 18px;
  color: ${(props) => props.color || '#EB5757'};
`;
