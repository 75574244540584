import { ApexOptions } from 'apexcharts';
// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export function BaseOptionChartStyle() {
  return (
    <GlobalStyles
      styles={{
        '&.apexcharts-canvas': {
          // Tooltip
          '.apexcharts-xaxistooltip': {
            display: 'none'
          },
          '.apexcharts-yaxistooltip': {
            display: 'none'
          },
          '.apexcharts-tooltip': {
            display: 'none'
          },
          // Legend
          '.apexcharts-legend': {
            padding: 0
          },
          '.apexcharts-legend-series': {
            display: 'flex !important',
            alignItems: 'center'
          },
          '.apexcharts-legend-marker': {
            marginRight: 8
          },
          '.apexcharts-legend-text': {
            lineHeight: '18px'
          },
          //active Bar
          '.active-bar': {
            filter: 'none'
          }
        }
      }}
    />
  );
}

export default function BaseOptionChart(): ApexOptions {
  const theme = useTheme();

  return {
    // Colors
    colors: [
      theme.palette.primary.main,
      // theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.chart.green[0],
      theme.palette.chart.red[0]
    ],

    // Chart
    chart: {
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },

    // States
    states: {
      normal: { filter: { type: 'none', value: 0 } },
      hover: { filter: { type: 'lighten', value: 0 } },
      active: { filter: { type: 'darken', value: 1 } }
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    // Stroke
    stroke: {
      width: 2,
      curve: 'smooth',
      lineCap: 'round'
    },

    // Xaxis
    xaxis: {
      type: 'category',
      axisBorder: { show: false },
      axisTicks: { show: false }
    },

    // Legend
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      fontFamily: 'Noto Sans KR, Noto Sans, Nanum Square',
      fontSize: String(13),
      position: 'top',
      horizontalAlign: 'right',
      markers: { radius: 12 },
      itemMargin: { horizontal: 12 },
      labels: { colors: theme.palette.text.primary }
    },

    plotOptions: { bar: { columnWidth: '10%', borderRadius: 4 } },

    // Datalabels
    dataLabels: { enabled: false },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },

    // Tooltip
    tooltip: { enabled: false, x: { show: false } },

    noData: { text: 'No Data', offsetY: -22 }
  };
}
