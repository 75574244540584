// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          textTransform: 'none'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%',
          msOverflowStyle: 'none',
          WebkitTextSizeAdjust: '100%',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)'
        },
        'div.MuiContainer-root.MuiContainer-maxWidthLg': {
          maxWidth: '1200px' //FIXME: Streched Styles
        },
        '.MuiTableContainer-root': {
          '&::-webkit-scrollbar': {
            display: 'none'
          }
          // '&::-webkit-scrollbar-track': {
          //   backgroundColor: 'transparent'
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   backgroundColor: '#b5b5b5',
          //   borderRadius: '100px'
          // }
        },
        a: {
          whiteSpace: 'nowrap',
          textTransform: 'none'
        },
        button: {
          whiteSpace: 'nowrap',
          textTransform: 'none'
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset'
          },
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: { display: 'block', maxWidth: '100%' },

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },

        // Input EndAdorment
        '.MuiInputBase-adornedEnd': {
          '.MuiIconButton-root': {
            padding: '12px !important'
          }
        },
        // Accordion Scroll
        '.MuiAccordion-root.subject': {
          '& .MuiCollapse-wrapperInner': {
            height: '192px',
            margin: '16px 0px',
            overflowY: 'scroll',
            '@media (min-width:600px)': {
              marginTop: '16px',
              height: '288px'
            }
          }
        },
        '.MuiAccordion-root.signup': {
          '& .MuiCollapse-wrapperInner': {
            height: '128px',
            margin: '16px 0px',
            marginTop: '8px',
            overflowY: 'scroll',
            '@media (min-width:600px)': {
              marginTop: '16px',
              height: '219px'
            }
          }
        }
      }}
    />
  );
}
