import { useContext } from 'react';
import { AuthContextType } from 'contexts/auth/AuthContext.type';
import { AuthContext } from 'contexts/auth/AuthContext';

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Auth context must be used inside AuthProvider');
  }
  return context;
};

export default useAuth;
