export enum BackendApiError {
  // [BackendApiContext/ResponseInterceptor]
  ServerResponseTimout = '[BackendApiContext/AxiosResponseInterceptor] Server response timout',
  RefreshTokenExpired = '[BackendApiContext/AxiosResponseInterceptor] Refresh token has been expired',
  APICallsOverFlow = '[BackendApiContext/AxiosResponseInterceptor] too many api calls at once',
  // [BackendApiContext/getServerStatus]
  ServerHealthSevere = '[BackendApiContext/getServerStatus] Bad server health',
  // [BackendApiContext/login]
  NotEnoughToLogin = '[BackendApiContext/login] Not enough information to log-in',
  ExceedMaxNumOfConcurrentLogin = '[BackendApiContext/login] Exceed max number of concurrent log-in.',
  InvalidEmailOrPasswordToLogin = '[BackendApiContext/login] Invalid e-mail or password to log-in',
  SignUpRequiredToLogin = '[BackendApiContext/login] User must sign-up before log-in',
  TokenPairExpired = '[BackendApiContext/login] Access and refresh tokens are all expired',
  // [BackendApiContext/logout]
  RefreshTokenNotFound = '[BackendApiContext/logout] Refresh token not found in async-storage for logout',
  RefreshTokenAlreadyExpired = '[BackendApiContext/logout] Refresh token is already expired during logout',
  // [BackendApiContext/verifySerialNumber]
  InvalidSerialNumber = '[BackendApiContext/verifySerialNumber] Invalid serial number',
  // [BackendApiContext/verifyEmail]
  InvalidEmail = '[BackendApiContext/verifyEmail] Invalid e-mail',
  // [BackendApiContext/verifyNumber]
  InvalidNumber = '[BackendApiContext/verifyNumber] Invalid subjectNumber',
  // [BackendApiContext/getLastLogin]
  UnregisteredEmailToGetLastLogin = '[BackendApiContext/getLastLogin] Unregistered e-mail to get last login info',
  // [BackendApiContext/registerUser]
  AlreadySignedUpEmailToRegisterUser = '[BackendApiContext/registerUser] Already signed-up e-mail to register user',
  InvalidEmailOrTokenToRegisterUser = '[BackendApiContext/registerUser] Invalid e-mail or token to register user',
  // [BackendApiContext/updateUser]
  InvalidEmailOrTokenToUpdateUser = '[BackendApiContext/updateUser] Invalid e-mail or token to update user',
  EmailIsAlredayTaken = '[BackendApiContext/updateUser] user with this email already exists',
  // [BackendApiContext/getExamResults]
  BadRequestError = '[BackendApiContext/getExamResults] bad Request Error',
  // [BackendApiContext/uploadExamResult]
  ExamDataNotFoundToUpload = '[BackendApiContext/uploadExamResult] Exam data not found to upload',
  // [BackendApiContext/exportSubjectSPPBData]
  ExportSubjectDataBadRequest = '[BackendApiContext/exportSubjectSPPBData] 400 Bad Request',
  ExportSubjectDataUnauthorized = '[BackendApiContext/exportSubjectSPPBData] 401 Unauthorized',
  ExportSubjectDataNotFound = '[BackendApiContext/exportSubjectSPPBData] 404 Not Found'
}
