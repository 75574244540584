import * as Yup from 'yup';
import { useState } from 'react';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, Alert, TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { AuthRouteStack, SubRouteStack, TextFieldStack } from './styles';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { LogInParams } from '@types';
import { BackendApiError } from 'contexts/backend/BackendApiError.type';
import BaseButton from 'components/BaseButton';
import useLocales from 'hooks/useLocales';

interface InitialValues extends LogInParams {
  afterSubmit?: string;
}

export default function LoginForm() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { signIn } = useAuth();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('EmailIsNotVaild'))
      .required(translate('LoginEmailLengthValidation')),
    password: Yup.string().required(translate('LoginPasswordLengthValidation'))
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const isActive = await signIn(values);

        if (isActive === undefined) {
          throw new Error(translate('ErrorLoginFailedAndRequestToRetry'));
        } else if (isActive === false) {
          navigate(PATH_AUTH.inactiveUser);
        } else {
          navigate(PATH_DASHBOARD.exams.overview);
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
          switch ((error as Error).message) {
            case BackendApiError.SignUpRequiredToLogin:
              setErrors({ afterSubmit: translate('ErrorLoginFailedRequestForSignUp') });
              break;
            case BackendApiError.InvalidEmailOrPasswordToLogin:
              setErrors({ afterSubmit: translate('ErrorLoginFailedWrongEmailOrPassword') });
              break;
            case BackendApiError.ExceedMaxNumOfConcurrentLogin:
              setErrors({ afterSubmit: translate('ErrorLoginMaxNumOfConcurrentLogin') });
              break;
            case BackendApiError.NotEnoughToLogin:
              setErrors({ afterSubmit: translate('ErrorLoginFailedAndRequestToRetry') });
              break;

            // Unknown error
            default:
              console.error(
                `[LoginForm/formik OnSubmit] Unknown error: ${(error as Error).message}`
              );
              break;
          }
        }
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form className="FullWidth" autoComplete="off" noValidate onSubmit={handleSubmit}>
        {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
        <TextFieldStack>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={translate('EmailAddress')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate('Password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </TextFieldStack>
        <AuthRouteStack>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Link to={PATH_AUTH.findEmail}>{translate('FindEmail')}</Link>
            <Box className="AuthRoute-Divider" />
            <Link to={PATH_AUTH.resetPassword}>{translate('FindPassword')}</Link>
            <Box className="AuthRoute-Divider" />
            <Link to={PATH_AUTH.signUp}>{translate('SignUp')}</Link>
          </Stack>
        </AuthRouteStack>
        <BaseButton>{translate('Login')}</BaseButton>
        <SubRouteStack>
          <Link to={PATH_AUTH.termsOfUse}>{translate('TermsOfUse')}</Link>
          <Link to={PATH_AUTH.privacyPolicy}>{translate('PrivacyPolicy')}</Link>
        </SubRouteStack>
      </Form>
    </FormikProvider>
  );
}
