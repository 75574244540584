import React from 'react';
import Page from 'components/Page';
import { AuthContent, Header, SmallLogo, LogoImg, LogoStack } from './styles';
import { Container } from '@mui/material';
import LoginForm from './LoginForm';
import LanguagePopover from 'layouts/dashboard/LanguagePopover';

export default function Login() {
  return (
    <Page title="Login">
      <Header>
        <SmallLogo src="/static/Logo.svg" alt="small-logo" loading="lazy" />
        <LanguagePopover />
      </Header>
      <Container maxWidth={'lg'}>
        <AuthContent maxWidth={480}>
          <LogoStack>
            <LogoImg src="/static/AndanteFitLogo.svg" alt="full-name-logo" />
          </LogoStack>
          <LoginForm />
        </AuthContent>
      </Container>
    </Page>
  );
}
