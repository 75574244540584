import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
// hooks
import { useWindowSize } from 'hooks/useWindowSize';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import { MIconButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import breakpoints from 'theme/breakpoints';

export default function AccountPopover() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  const { user, signOut } = useAuth();
  const { width } = useWindowSize();

  const [open, setOpen] = useState(false);

  const imageSize = width && width < breakpoints.values.md ? '36px' : '44px';

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error('[AccountPopover/handleLogout] Logout Error', error);
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          marginLeft: 3,
          width: imageSize,
          height: imageSize,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.15)
            }
          })
        }}
      >
        <img width={'100%'} height={'100%'} src={'/static/ic_person.png'} alt="account" />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.organization} {user?.division}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          to={PATH_DASHBOARD.management.userAccountSetting}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          {translate('AccountSetting')}
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {translate('UserLogout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
