export const PDF_STORAGE_KEY = {
  fileName: '@PDF_FILE_NAME',
  fileFormat: '@PDF_FILE_FORMAT',
  userInput: '@PDF_USER_INPUT'
} as const;
export type FileNameId = 'userInput' | 'number' | 'name' | 'examDate' | 'organization' | 'examName';
export type FileNameTitle =
  | 'UserInput'
  | 'SubjectNumber'
  | 'Name'
  | 'ExamCompleted'
  | 'Organization'
  | 'ExamTypeShort';
export type FileNameSample =
  | 'UserInputSample'
  | 'SubjectNumberSample'
  | 'NameSample'
  | 'ExamCompletedSample'
  | 'OrganizationSample'
  | 'ExamNameSample';
export type FileNameFormatType = {
  id: FileNameId;
  title: FileNameTitle;
};
