const breakpoints = {
  values: {
    xs: 0,
    sm: 600, //mobile
    md: 900, //tablet
    lg: 1200, //desktop
    xl: 1536
  }
};

export default breakpoints;
