import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from 'hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'white',
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',
  paddingTop: '72px',
  paddingBottom: theme.spacing(5),
  paddingLeft: '0px',
  paddingRight: '0px',
  marginTop: '16px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: '92px',
    paddingBottom: '60px',
    marginTop: '24px'
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: '92px',
    paddingBottom: '60px',
    marginTop: '24px'
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => {
          console.log('opened ?', open);
          setOpen(!open);
        }}
      />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '160px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
