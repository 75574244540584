// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
export const GUIDELINE = '/guidelines';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/',
  signUp: path(ROOTS_AUTH, '/sign-up'),
  findEmail: path(ROOTS_AUTH, '/find-email'),
  emailAuth: path(ROOTS_AUTH, '/email-auth'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  inactiveUser: path(ROOTS_AUTH, '/inactive-user'),
  privacyPolicy: path(ROOTS_AUTH, '/privacy-policy'),
  termsOfUse: path(ROOTS_AUTH, '/terms-of-use')
};

export const PATH_PAGE = {
  // comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page401: '/401',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  exams: {
    overview: path(ROOTS_DASHBOARD, '/overview'),
    SPPB: path(ROOTS_DASHBOARD, '/sppb-exams'),
    GaitSpeed: path(ROOTS_DASHBOARD, '/gaitspeed-exams'),
    SitToStand: path(ROOTS_DASHBOARD, '/sittostand-exams'),
    StaticBalance: path(ROOTS_DASHBOARD, '/staticbalance-exams'),
    AverageGaitSpeed: path(ROOTS_DASHBOARD, '/averagegaitspeed-exams'),
    OneLegStanding: path(ROOTS_DASHBOARD, '/onelegstanding-exams'),
    TUG: path(ROOTS_DASHBOARD, '/tug-exams'),
    HandgripStrength: path(ROOTS_DASHBOARD, '/handgripstrength-exams')
  },
  management: {
    userAccountSetting: path(ROOTS_DASHBOARD, '/useraccount-setting'),
    emailChange: path(ROOTS_DASHBOARD, '/email-change'),
    passwordChange: path(ROOTS_DASHBOARD, '/password-change'),
    subjectManagement: path(ROOTS_DASHBOARD, '/subject-management'),
    customerService: path(ROOTS_DASHBOARD, `/customer-service`),
    // Admin Only
    adminDashboard: path(ROOTS_DASHBOARD, '/admin'),
    reportDashboard: path(ROOTS_DASHBOARD, '/admin/report'),
    qualityControl: path(ROOTS_DASHBOARD, '/admin/quality-control'),
    licenseManagement: path(ROOTS_DASHBOARD, '/admin/license-management')
  }
};
