export type SingleExamType =
  | 'GaitSpeed'
  | 'StaticBalance'
  | 'SitToStand'
  | 'AverageGaitSpeed'
  | 'TUG'
  | 'OneLegStanding'
  | 'HandgripStrength';

export type ExamType = SingleExamType | 'SPPB';
export type ExamTypeExtended = ExamType | 'All';

export const SupportedExamList: ExamType[] = [
  'SPPB',
  'GaitSpeed',
  'SitToStand',
  'StaticBalance',
  'AverageGaitSpeed',
  'TUG',
  'OneLegStanding',
  'HandgripStrength'
];

export type StaticBalanceSubExam = 'side-by-side' | 'semi-tandem' | 'tandem';
export type SitToStandSubType = 'Timeout' | 'CountLimit';
export type OneLegStandingSubExam = 'eyes-open' | 'eyes-closed';
