import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import { DeviceInfo, LogInParams, Subject, User, AdminType } from '@types';
import { ActionMap } from '..';
import { AuthState, AuthContextType } from './AuthContext.type';
import useBackendApi from 'hooks/useBackendApi';

import { BackendApiError } from 'contexts/backend/BackendApiError.type';
import { StorageKey } from '../backend/BackendApiContext';

enum Types {
  Initial = 'INITIALIZE',
  SetStaff = 'SET_STAFF',
  SetDevices = 'SET_DEVICE_LIST',
  SetUser = 'SET_USER',
  ChangeUserInfo = 'CHANGE_USER_INFO',
  CheckSubject = 'CHECK_SUBJECT'
}

type AuthPayload = {
  [Types.Initial]: undefined;
  [Types.SetDevices]: { devices: DeviceInfo[] };
  [Types.SetStaff]: { isStaff: AdminType };
  [Types.SetUser]: { user: User | null };
  [Types.ChangeUserInfo]: { userInfo: Partial<User> };
  [Types.CheckSubject]: { subject: Omit<Subject, 'user'> | undefined };
};

type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  isStaff: null,
  user: null,
  devices: [],
  subject: undefined
};

export const reducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        isInitialized: true
      };

    case Types.SetStaff:
      return {
        ...state,
        isStaff: action.payload.isStaff
      };

    case Types.SetDevices:
      return {
        ...state,
        devices: action.payload.devices
      };

    case Types.SetUser:
      return {
        ...state,
        user: action.payload.user
          ? {
              ...state.user,
              id: action.payload.user.id,
              email: action.payload.user.email,
              organization: action.payload.user.organization,
              date_joined: action.payload.user.date_joined,
              division: action.payload.user.division,
              timezone: action.payload.user.timezone
            }
          : null,
        isInitialized: true,
        isAuthenticated: !!action.payload.user
      };

    case Types.ChangeUserInfo:
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              email: action.payload.userInfo.email ?? state.user.email,
              organization: action.payload.userInfo.organization ?? state.user.organization
            }
          : state.user
      };

    case Types.CheckSubject:
      return {
        ...state,
        subject: action.payload.subject
      };

    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { login, logout, getUser, getDeviceInfo, registerUser, updateUser, deleteUser } =
    useBackendApi();

  // Initializer
  // useEffect(() => {
  //   // TODO: Server Health Check

  //   dispatch({ type: Types.Initial });
  // }, []);

  // Methods
  const signIn = useCallback(
    async (params?: LogInParams) => {
      try {
        console.log('[AuthContext/signIn] Trying to log-in...');
        const isActive = await login(params);

        // Check if user account is inactive
        if (isActive === false) {
          console.log('[AuthContext/signIn] This account has been de-activated!');
          return isActive;
        }

        // If user account is active...
        // Get user information
        const user = await getUser();
        const isStaff = (await localStorage.getItem(StorageKey.isStaff)) as AdminType;

        if (isStaff !== null) {
          dispatch({ type: Types.SetStaff, payload: { isStaff } });
        }
        if (user) {
          dispatch({ type: Types.SetUser, payload: { user } });
          // Get device information
          const devices = await getDeviceInfo();
          dispatch({
            type: Types.SetDevices,
            payload: {
              devices: devices
            }
          });
          console.log('[AuthContext/getUserInfo] Retrieved user info:', user);
          console.log(
            '[AuthContext/getDevice] Retrieved device list:',
            devices.map((d) => d.device.serial_number)
          );

          // Return account active state
          console.log('[AuthContext/signIn] Sign-in success!');
        } else {
          dispatch({ type: Types.Initial });
        }

        return isActive;
      } catch (error) {
        console.log('[AuthContext/signIn] Require user sign-in');
        dispatch({ type: Types.SetUser, payload: { user: null } });
        dispatch({ type: Types.SetDevices, payload: { devices: [] } });
        //
        switch ((error as Error).message) {
          case BackendApiError.SignUpRequiredToLogin:
          case BackendApiError.InvalidEmailOrPasswordToLogin:
          case BackendApiError.ExceedMaxNumOfConcurrentLogin:
          case BackendApiError.NotEnoughToLogin:
          default:
            console.error(error);
            throw error;
        }
      }
    },
    [getDeviceInfo, getUser, login]
  );

  const signOut = useCallback(async () => {
    try {
      await logout();
    } catch (error) {
      switch ((error as Error).message) {
        case BackendApiError.RefreshTokenAlreadyExpired:
        case BackendApiError.RefreshTokenNotFound:
        default:
          throw error;
      }
    } finally {
      dispatch({ type: Types.SetUser, payload: { user: null } });
      dispatch({ type: Types.SetDevices, payload: { devices: [] } });
      console.log('[AuthContext/signOut] Log-out');
    }
  }, [logout]);

  const signUp = useCallback(
    async (
      temporaryToken: string,
      email: string,
      password: string,
      organization?: string
    ): Promise<User> => {
      try {
        const user = await registerUser(temporaryToken, email, password, organization);
        console.log('[AuthContext/signUp] Sign-up success!');
        return user;
      } catch (error) {
        switch ((error as Error).message) {
          case BackendApiError.UnregisteredEmailToGetLastLogin:
          case BackendApiError.AlreadySignedUpEmailToRegisterUser: // Already signed up email (This error should not happen)
          case BackendApiError.InvalidEmailOrTokenToRegisterUser: // Invalid e-mail and/or temporary token (This error should not happen)
          default:
            throw error;
        }
      }
    },
    [registerUser]
  );

  const setUser = (user: User) => {
    dispatch({
      type: Types.SetUser,
      payload: { user }
    });
  };

  const changeEmail = useCallback(
    async (email: string) => {
      try {
        await updateUser('email', email);
        // dispatch({
        //   type: Types.ChangeUserInfo,
        //   payload: { userInfo: { email: email } }
        // });
        // await signOut();
      } catch (error) {
        switch ((error as Error).message) {
          case BackendApiError.InvalidEmailOrTokenToUpdateUser:
          case BackendApiError.EmailIsAlredayTaken:
          default:
            throw error;
        }
      }
    },
    [updateUser]
  );

  const changeOrganization = useCallback(
    async (organization: string) => {
      try {
        const result = await updateUser('organization', organization);
        dispatch({
          type: Types.ChangeUserInfo,
          payload: { userInfo: { organization: organization } }
        });
        return result;
      } catch (error) {
        switch ((error as Error).message) {
          case BackendApiError.InvalidEmailOrTokenToUpdateUser:
          default:
            console.error(error);
            throw error;
        }
      }
    },
    [updateUser]
  );

  const changePassword = useCallback(
    async (password: string) => {
      try {
        await updateUser('password', password);
        await signOut();
      } catch (error) {
        switch ((error as Error).message) {
          case BackendApiError.InvalidEmailOrTokenToUpdateUser:
          default:
            console.error(error);
            throw error;
        }
      }
    },
    [signOut, updateUser]
  );

  const checkSubject = useCallback((subject: Omit<Subject, 'user'> | undefined) => {
    dispatch({
      type: Types.CheckSubject,
      payload: { subject: subject }
    });
  }, []);

  const deleteUserAccount = useCallback(async () => {
    try {
      await deleteUser();
      await signOut();
      console.log('[AuthContext/deleteUserAccount] user account is deleted');
    } catch (error) {
      switch ((error as Error).message) {
        case BackendApiError.InvalidEmailOrTokenToUpdateUser:
        default:
          console.error(error);
          throw error;
      }
    }
  }, [deleteUser, signOut]);

  // Auth state checker
  useEffect(() => {
    signIn().catch((error) => {
      console.log('[AuthContext/AuthStateChecker] Failed to sign-in:', error);

      switch ((error as Error).message) {
        case BackendApiError.ExceedMaxNumOfConcurrentLogin:
        case BackendApiError.InvalidEmailOrPasswordToLogin:
          break;

        case BackendApiError.SignUpRequiredToLogin:
        case BackendApiError.NotEnoughToLogin:
        case BackendApiError.TokenPairExpired:
          // dispatch({ type: Types.SetUser, payload: { user: null } });
          // dispatch({ type: Types.SetDevices, payload: { devices: [] } });
          break;

        default:
          break;
      }
    });
  }, [signIn]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn: signIn,
        signOut: signOut,
        signUp: signUp,
        setUser: setUser,
        changeEmail: changeEmail,
        changeOrganization: changeOrganization,
        changePassword: changePassword,
        checkSubject: checkSubject,
        deleteUserAccount: deleteUserAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
