import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';
// routes
import { GUIDELINE, PATH_DASHBOARD } from '../routes/paths';
import AndanteFitMetaTag from '../utils/setMetatag';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const { currentLang } = useLocales();

  console.log('[GuestGuard] isAuthenticated : ', isAuthenticated);

  if (window.location.href.includes('andantefit.com/guidelines')) {
    return <Navigate to={GUIDELINE} />;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return (
    <>
      <AndanteFitMetaTag currentLang={currentLang.value} />
      {children}
    </>
  );
}
