import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enLocales from './en.json';
import koLocales from './ko.json';
import jaLocales from './ja.json';

// ----------------------------------------------------------------------

declare module 'react-i18next' {
  // and extend them!
  interface CustomTypeOptions {
    // custom namespace type if you changed it
    defaultlang: 'ko';
    // custom resources type
    resources: {
      ko: typeof koLocales;
      en: typeof enLocales;
      ja: typeof jaLocales;
    };
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ko: { translations: koLocales },
      en: { translations: enLocales },
      ja: { translations: jaLocales }
    },
    lng: localStorage.getItem('i18nextLng') || window.navigator.language.substring(0, 2) || 'ko',
    fallbackLng: 'ko',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
