import styled from '@emotion/styled';
import ErrorIcon from '@mui/icons-material/Error';
import WarningRoundedIcon from '@mui/icons-material/Warning';

/**
 * @CustomErrorIcon
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle;
    margin-bottom: 2px;
 */
export const CustomErrorIcon = styled(ErrorIcon)`
  font-size: 16px;
  line-height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

/**
 * @CustomWarningIcon
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle;
    margin-bottom: 2px;
 */
export const CustomWarningIcon = styled(WarningRoundedIcon)`
  font-size: 16px;
  line-height: 18px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

/**
 * @LargeIcon
  width: 20px;
  height: 20px;
 */
export const LargeIcon = styled('img')`
  width: 44px;
  height: 44px;
`;

/**
 * @MediumIcon
  width: 20px;
  height: 20px;
 */
export const MediumIcon = styled('img')`
  width: 22px;
  height: 22px;
`;

/**
 * @SmallIcon
  width: 20px;
  height: 20px;
 */
export const SmallIcon = styled('img')`
  width: 20px;
  height: 20px;
`;

/**
 * @XSmallIcon
  width: 18px;
  height: 18px;
 */
export const XSmallIcon = styled('img')`
  width: 18px;
  height: 18px;
`;
