import { useContext } from 'react';
import { BackendApiContext } from 'contexts/backend/BackendApiContext';
import { BackendApiContextType } from 'contexts/backend/BackendApiContext.type';

const useBackendApi = (): BackendApiContextType => {
  const context = useContext(BackendApiContext);
  if (!context) {
    throw new Error('Backend API context must be used inside BackendApiProvider');
  }
  return context;
};

export default useBackendApi;
