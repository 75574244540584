// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props {
  sx?: BoxProps;
  width?: string;
  height?: string;
}

export default function Logo({ sx, width, height }: Props) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img
        style={{ width: width ? width : undefined, height: height ? height : undefined }}
        src={'/static/Logo.svg'}
        alt="Logo"
      />
    </Box>
  );
}
