/* eslint-disable @typescript-eslint/dot-notation */
import React, { createContext, useCallback, useLayoutEffect } from 'react';
// import { ActionMap } from '..';
import { BackendApiContextType } from './BackendApiContext.type';
import axios, { AxiosError, AxiosResponse } from 'axios';

import {
  DeviceInfo,
  ExamType,
  ExamTypeExtended,
  SupportedExamList,
  ExamResultListProps,
  LogInParams,
  Ordering,
  SubjectListProps,
  Subject,
  User,
  calculatePhysiologicalIndexProps,
  ExamResultProps,
  ExamSettingsSitToStand,
  SensorRawData,
  AdminReportListProps,
  LicenseManagementResponseType,
  LicenseManagementBodyType,
  QualityControlResponseType,
  QualityControlPostDataProps
} from '@types';
import { HttpStatusCode } from './HttpStatusCode';
import {
  LastLoginTimeResponse,
  RegisterOrUpdateUserResponseError,
  ServerTimeResponse,
  TemporaryTokenResponse,
  TokenDiscardResponseError,
  TokenResponse,
  TokenResponseError,
  VerifyPasswordResponse,
  VerifyNumberResponse,
  QRDataResponse
} from './BackendApiResponse.type';
import { ApiEndPoint } from './BackendApiRequest.type';
import { BackendApiError } from './BackendApiError.type';
import platform from 'platform';
import base64 from 'base-64';

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const browserInfo = platform.description;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: { 'Platform-Type': browserInfo }
});

export const StorageKey = {
  isStaff: '@isStaff',
  accessToken: '@accessToken',
  refreshToken: '@refreshToken'
} as const;

const BackendApiContext = createContext<BackendApiContextType | null>(null);

// variables to control mulitple requests ====================
const MAX_REQUEST_ARRAY_LENGTH = 150;

let isTokenRefreshing: boolean = false;

let requestArrayToBeExcuted: {
  resolve: (value: string | null) => void;
  reject: (error: Error) => void;
}[] = [];
// ================================================================

function BackendApiProvider({ children }: { children: React.ReactNode }): JSX.Element {
  // excution func to control mulitple requests ====
  const processRequests = (error: Error | null, token: string | null = null) => {
    console.log('[BackendApiContext/processRequests] token param in processRequests', token);

    requestArrayToBeExcuted.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    requestArrayToBeExcuted = [];
  };
  // ================================================================

  console.log('[BackendApiContext/BackendApiProvider] baseURL', BASE_URL);

  // Utils
  const renewTokenPair = useCallback(async (accessToken: string, refreshToken: string) => {
    // Reset refresh token in async storage
    console.log('[BackendApiContext/renewTokenPair] Put new token pair to async storage');
    localStorage.setItem(StorageKey.accessToken, accessToken);
    localStorage.setItem(StorageKey.refreshToken, refreshToken);

    // Re-configure default Authroization header value
    console.log('[BackendApiContext/renewTokenPair] Re-configure default Authroization header...');

    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }, []);

  // Axios interceptors
  //Note: verifyToken은 renewToken 로직을 돌아야 하므로 아래 목록에 포함 X
  const isAuthHeaderFree = useCallback(
    (url?: string) =>
      //로그인 이전
      url === ApiEndPoint.health ||
      url === ApiEndPoint.verify + 'serialNumber' ||
      url === ApiEndPoint.verify + 'email' ||
      url === ApiEndPoint.verify + 'lastLogin' ||
      url === ApiEndPoint.updateUser + 'all' ||
      //로그인 관련
      url === ApiEndPoint.issueToken ||
      url === ApiEndPoint.refreshTokenPair ||
      //로그인 이전 및 이후
      url === ApiEndPoint.sendAuthLink ||
      url === ApiEndPoint.updateUser + 'password',
    []
  );

  //Note: discardToken은 response에서 accessToken 만료 401에러가 나더라도, token refresh가 불필요하므로 추가
  const shouldBypassAxiosInterceptor = useCallback(
    (url?: string) => isAuthHeaderFree(url) || url === ApiEndPoint.discardToken,
    [isAuthHeaderFree]
  );

  useLayoutEffect(() => {
    console.log('[BackendApiContext/AxiosInterceptor] Configuring axios interceptor...');

    console.log('[BackendApiContext/AxiosInterceptor] Browser info in hedaer:', browserInfo);

    // Axios request interceptor
    axiosInstance.interceptors.request.use(
      (config) => {
        console.log(
          `[BackendApiContext/AxiosRequestInterceptor] Request info
                - URL: ${config.url ?? '-'}
                - Header.Authorization: ${config.headers?.common?.Authorization ?? '-'}            
                `
        );

        return config;
      },
      (error: AxiosError) => {
        console.error(
          `[BackendApiContext/AxiosRequestInterceptor] Request error: ${error.message}`
        );
        return Promise.reject(error);
      }
    );
    // Axios response interceptor
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        console.log(
          `[BackendApiContext/AxiosResponseInterceptor] API response error!
          - Code: ${error.response?.status ?? 'unknown'}
          - Message: `,
          error.response?.data
        );

        // At first, handle timeout error
        if (error.code === 'ECONNABORTED') {
          return Promise.reject(new Error(BackendApiError.ServerResponseTimout));
        }

        // Extract original request config
        const originalRequest = error.config;

        // Prevent inifinite recursion
        if (originalRequest?.headers?.__isRetryRequest) {
          console.log(
            '[BackendApiContext/AxiosResponseInterceptor] Error on retrying original request. Reject token refreshing...'
          );
          return Promise.resolve(error.response);
        }

        // Bypass some api calls
        if (shouldBypassAxiosInterceptor(originalRequest?.url)) {
          console.log(
            `[BackendApiContext/AxiosResponseInterceptor] (${
              originalRequest?.url ?? 'unknown url'
            }) Bypassing axios interceptor...`
          );
          return Promise.resolve(error.response);
        }

        // Check authroization header and do automatic token refresh if necessary
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          console.log(
            '[BackendApiContext/AxiosResponseInterceptor]',
            originalRequest?.url,
            error.response?.data
          );

          // console.log('1. isTokenRefreshing===============>', isTokenRefreshing);

          if (!isTokenRefreshing) {
            // Lock token refresh guard
            isTokenRefreshing = true;
            // console.log('2. isTokenRefreshing===============>', isTokenRefreshing);

            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] Default header:',
              axiosInstance.defaults.headers.common
            );

            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] OriginalHeader:',
              originalRequest?.headers
            );

            // Delete previous access token in default header as well as original request header
            delete axiosInstance.defaults.headers.common.Authorization;

            if (originalRequest?.headers?.Authorization) {
              console.log(
                '[BackendApiContext/AxiosResponseInterceptor] Deleting auth header in original request...'
              );
              delete originalRequest.headers.Authorization;
            }

            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] Default header after delete Authorization:',
              axiosInstance.defaults.headers.common
            );

            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] OriginalHeader after delete Authorization:',
              originalRequest?.headers
            );

            // If original request is requires auth-free header...
            if (isAuthHeaderFree(originalRequest?.url)) {
              // Release token refresh guard
              isTokenRefreshing = false;
              console.log('3. isTokenRefreshing===============>', isTokenRefreshing);

              // Redo original request without token
              console.log(
                '[BackendApiContext/AxiosInterceptor] Redo original requests without token'
              );

              console.log(
                '[BackendApiContext/AxiosResponseInterceptor] Default header before redo without token:',
                axiosInstance.defaults.headers
              );

              console.log(
                '[BackendApiContext/AxiosResponseInterceptor] OriginalHeader before redo without token:',
                originalRequest?.headers
              );

              if (originalRequest?.headers) {
                originalRequest.headers.__isRetryRequest = 'yes';
                return axiosInstance(originalRequest);
              } else {
                return Promise.resolve(error.response);
              }
            }

            // Get refresh token in async storage
            const refreshToken = localStorage.getItem(StorageKey.refreshToken);

            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] Retrieve stored refresh token:',
              refreshToken ? 'ok' : null
            );

            // Reqeust to server for token pair renewal
            console.log(
              '[BackendApiContext/AxiosResponseInterceptor] Request token pair renewal to server...'
            );

            const resp = await axiosInstance.post(ApiEndPoint.refreshTokenPair, {
              refresh_token: refreshToken
            });
            console.log(
              `[BackendApiContext/AxiosResponseInterceptor] Server response: ${resp.status} `,
              resp.data
            );
            if (resp.status === HttpStatusCode.OK) {
              const tokenPair = (resp.data as TokenResponse).token;

              await renewTokenPair(tokenPair.access_token, tokenPair.refresh_token);

              console.log(
                '[BackendApiContext/AxiosResponseInterceptor] Default header after token re-configure:',
                axiosInstance.defaults.headers.common
              );

              console.log(
                '[BackendApiContext/AxiosResponseInterceptor] OriginalHeader after token re-configure:',
                originalRequest?.headers
              );

              // Redo original request with renewed token
              const newAccessToken = localStorage.getItem(StorageKey.accessToken);
              console.log(
                '[BackendApiContext/AxiosInterceptor] Redo original requests with new token',
                newAccessToken
              );

              if (originalRequest?.headers) {
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers.__isRetryRequest = 'yes';
                console.log(
                  '[BackendApiContext/AxiosResponseInterceptor] Default header before redo with token:',
                  axiosInstance.defaults.headers.common
                );
                console.log(
                  '[BackendApiContext/AxiosResponseInterceptor] OriginalHeader before redo with token:',
                  originalRequest?.headers
                );

                // Re-configure access token in body in case of token verification
                if (originalRequest.url === ApiEndPoint.verifyToken) {
                  console.log(
                    '[BackendApiContext/AxiosResponseInterceptor] Original request data before token hacking:',
                    originalRequest.data
                  );
                  originalRequest.data = {
                    access_token: newAccessToken
                  };
                  console.log(
                    '[BackendApiContext/AxiosResponseInterceptor] Original request data after token hacking:',
                    originalRequest.data
                  );
                }

                requestArrayToBeExcuted.length > 0 && processRequests(null, newAccessToken);
                // console.log('requestArrayToBeExcuted', requestArrayToBeExcuted);

                // Release token refresh guard
                isTokenRefreshing = false;
                // console.log('4. isTokenRefreshing===============>', isTokenRefreshing);

                return axiosInstance(originalRequest);
              }
            } else {
              // Release token refresh guard

              isTokenRefreshing = false;
              processRequests(error, null);
              // console.log('5. isTokenRefreshing===============>', isTokenRefreshing);
            }
          } else {
            if (requestArrayToBeExcuted.length < MAX_REQUEST_ARRAY_LENGTH) {
              return new Promise<string | null>((resolve, reject) => {
                // Put resolve in the queue, save it in a function form, and execute it directly after token refreshes
                requestArrayToBeExcuted.push({ resolve, reject });
                console.log(
                  '[BackendApiContext/AxiosResponseInterceptor] request is added to requestArrayToBeExcuted ',
                  requestArrayToBeExcuted
                );
              })
                .then((token) => {
                  originalRequest.headers['Authorization'] = `Bearer ${token}`;
                  return axiosInstance(originalRequest);
                })
                .catch((error) => {
                  return Promise.reject(error);
                });
            } else {
              error.response.status = HttpStatusCode.TooManyRequests;
              error.response.data = BackendApiError.APICallsOverFlow;

              requestArrayToBeExcuted = [];
              return Promise.resolve(error.response);
            }
          }
        }
        return Promise.resolve(error.response);
      }
    );
    console.log('[BackendApiContext/AxiosInterceptor] Success to configure');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Health
  const getServerHealth = useCallback(async () => {
    const response = await axiosInstance.get(ApiEndPoint.health);
    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.InternalServerError:
        throw new Error(BackendApiError.ServerHealthSevere);

      default:
        throw new Error(
          `[BackendApiContext/getServerStatus] Unhandled response: ${response.status}`
        );
    }
  }, []);

  // Token
  const login = useCallback(
    async (param?: LogInParams) => {
      if (param) {
        const response = await axiosInstance.post(ApiEndPoint.issueToken, {
          email: param.email,
          password: param.password
        });
        switch (response.status) {
          case HttpStatusCode.OK: {
            const { token, is_active_account, is_staff } = response.data as TokenResponse;
            console.log(
              '[BackendApiContext/login/LogInParams] Log-in success. Active user?:',
              is_active_account
            );
            console.log(
              '[BackendApiContext/login/LogInParams] Log-in success. is staff?:',
              is_staff
            );
            if (is_staff) {
              await localStorage.setItem(StorageKey.isStaff, 'admin');
            } else {
              await localStorage.setItem(StorageKey.isStaff, 'user');
            }
            await renewTokenPair(token.access_token, token.refresh_token);
            // Note: e-mail & password를 이용한 log-in시도 시 response로 활성계정 여부를 return
            return is_active_account;
          }

          case HttpStatusCode.BadRequest: {
            const { error } = response.data as TokenResponseError;
            if (error) {
              throw new Error(BackendApiError.ExceedMaxNumOfConcurrentLogin);
            } else {
              throw new Error(
                `[BackendApiContext/login/LogInParams] This error should not occur (${response.status})`
              );
            }
          }

          case HttpStatusCode.Unauthorized: {
            const { error, detail } = response.data as TokenResponseError;
            if (error) {
              throw new Error(BackendApiError.SignUpRequiredToLogin);
            } else if (detail) {
              throw new Error(BackendApiError.InvalidEmailOrPasswordToLogin);
            } else {
              throw new Error(
                `[BackendApiContext/login/LogInParams] This error should not occur (${response.status})`
              );
            }
          }

          default:
            throw new Error(
              `[BackendApiContext/login/LogInParams] Unhandled response: ${response.status} ${response.data.detail}`
            );
        }
      } else {
        const accessToken = localStorage.getItem(StorageKey.accessToken);
        console.log(
          '[BackendApiContext/login/Token] Access token in async storage:',
          accessToken ? 'ok' : null
        );

        if (accessToken) {
          console.log('[BackendApiContext/login/Token] Request token verification to server...');
          const response = await axiosInstance.post(ApiEndPoint.verifyToken, {
            access_token: accessToken
          });
          console.log(
            '[BackendApiContext/login/Token] Server response:',
            response.status === HttpStatusCode.OK ? 'ok' : response.status
          );
          switch (response.status) {
            case HttpStatusCode.OK:
              // Note: Set default header for remember-me

              //## verify에서 401에러가 난 후, renewToken 한다음 이곳으로 들어오면, 갱신 전의 accessToken을 가져오므로, 현재 localStorage의 token을 다시 가져와 최신으로 넣어야,
              // login process의 login 후 이어지는 user api에서 401 에러 발생 하지 않음
              const newAccessToken = localStorage.getItem(StorageKey.accessToken);
              console.log(
                '[BackendApiContext/login/Token] accessToken set to axiosInstance default header',
                newAccessToken
              );
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
              // Note: Token verification의 경우 API response === empty
              return undefined;

            case HttpStatusCode.BadRequest:
              throw new Error(
                `[BackendApiContext/login/Token] This error should not occur (${response.status}): ${response.data.detail}`
              );

            case HttpStatusCode.Unauthorized:
              throw new Error(BackendApiError.TokenPairExpired);

            default:
              throw new Error(
                `[BackendApiContext/login/Token] Unhandled response: ${response.status} ${response.data.detail}`
              );
          }
        } else {
          // 로그인을 위한 LogInParms (e-mail & password) 과 token 정보 모두 부족한 경우
          throw new Error(BackendApiError.NotEnoughToLogin);
        }
      }
    },
    [renewTokenPair]
  );

  const logout = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem(StorageKey.refreshToken);
      console.log(
        '[BackendApiContext/logout] Retrieve stored refresh token:',
        refreshToken ? 'ok' : null
      );
      if (refreshToken) {
        console.log('[BackendApiContext/logout] Request to server to put token in blacklist...');

        const response = await axiosInstance.post(ApiEndPoint.discardToken, {
          refresh_token: refreshToken
        });
        switch (response.status) {
          case HttpStatusCode.OK:
            console.log('[BackendApiContext/logout] Server ok');
            break;

          case HttpStatusCode.BadRequest: {
            const { code } = response.data as TokenDiscardResponseError;
            if (code) {
              throw new Error(BackendApiError.RefreshTokenAlreadyExpired);
            } else {
              throw new Error(
                `[BackendApiContext/logout] This error should not occur (${response.status}): ${response.data.detail}`
              );
            }
          }
          case HttpStatusCode.Unauthorized:
            throw new Error(
              `[BackendApiContext/logout] This error should not occur (${response.status}): ${response.data.detail}`
            );

          default:
            throw new Error(
              `[BackendApiContext/logout] Unhandled response: ${response.status} ${response.data.detail}`
            );
        }
      } else {
        throw new Error(BackendApiError.RefreshTokenNotFound);
      }
    } finally {
      console.log('[BackendApiContext/logout] Remove token pairs in async storage');

      delete axiosInstance.defaults.headers.common['Authorization'];
      localStorage.removeItem(StorageKey.accessToken);
      localStorage.removeItem(StorageKey.refreshToken);
    }
  }, []);

  // Server time
  const getServerTime = useCallback(async () => {
    const response = await axiosInstance.get(ApiEndPoint.ping);
    switch (response.status) {
      case HttpStatusCode.OK:
        return new Date((response.data as ServerTimeResponse).ping); // Note: UTC timezone

      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/getServerTime] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/getServerTime] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // Email authentication
  const sendAuthLink = useCallback(async (email: string, link: string) => {
    const response = await axiosInstance.post(ApiEndPoint.sendAuthLink, {
      email: email,
      link: link
    });

    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as TemporaryTokenResponse;

      case HttpStatusCode.BadRequest:
        throw new Error(
          `[BackendApiContext/sendAuthLink] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/sendAuthLink] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // Verification
  const verifySerialNumber = useCallback(async (serialNumber: string) => {
    const response = await axiosInstance.post(ApiEndPoint.verify + 'serialNumber', {
      serialNumber: serialNumber
    });

    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as User[];

      case HttpStatusCode.BadRequest:
        throw new Error(
          `[BackendApiContext/verifySerialNumber] This error should not occur (${response.status}): ${response.data.detail}`
        );

      case HttpStatusCode.Unauthorized:
        throw new Error(BackendApiError.InvalidSerialNumber);

      default:
        throw new Error(
          `[BackendApiContext/verifySerialNumber] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const verifyEmail = useCallback(async (email: string) => {
    const response = await axiosInstance.post(ApiEndPoint.verify + 'email', {
      email: email
    });
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as User;

      case HttpStatusCode.BadRequest:
        throw new Error(
          `[BackendApiContext/verifyEmail] This error should not occur (${response.status}): ${response.data.detail}`
        );

      case HttpStatusCode.Unauthorized:
        throw new Error(BackendApiError.InvalidEmail);

      default:
        throw new Error(
          `[BackendApiContext/verifyEmail] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const verifyPassword = useCallback(async (password: string) => {
    const response = await axiosInstance.post(ApiEndPoint.verify + 'password', {
      password: password
    });
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as VerifyPasswordResponse;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/verifyPassword] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/verifyPassword] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const verifyNumber = useCallback(async (number: string) => {
    const response = await axiosInstance.post(ApiEndPoint.verify + 'number', {
      number: number
    });
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as VerifyNumberResponse;

      case HttpStatusCode.BadRequest:
        throw new Error(
          `[BackendApiContext/verifyNumber] This error should not occur (${response.status}): ${response.data.detail}`
        );

      case HttpStatusCode.Unauthorized:
        throw new Error(BackendApiError.InvalidNumber);

      default:
        throw new Error(
          `[BackendApiContext/verifyNumber] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const getLastLogin = useCallback(async (email: string) => {
    const response = await axiosInstance.post(ApiEndPoint.verify + 'lastLogin', { email: email });
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as LastLoginTimeResponse;

      case HttpStatusCode.BadRequest:
        throw new Error(
          `[BackendApiContext/getLastLogin] This error should not occur (${response.status}): ${response.data.detail}`
        );

      case HttpStatusCode.Unauthorized:
        throw new Error(BackendApiError.UnregisteredEmailToGetLastLogin);

      default:
        throw new Error(
          `[BackendApiContext/getLastLogin] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // User
  const getUser = useCallback(async () => {
    const response = await axiosInstance.get(ApiEndPoint.user);
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as User;

      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/getUser] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/getUser] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const registerUser = useCallback(
    async (temporaryToken: string, email: string, password: string, organization?: string) => {
      const response = await axiosInstance.patch(ApiEndPoint.updateUser + 'all', {
        token: temporaryToken,
        email: email,
        organization: organization,
        password: password
      });
      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as User;

        case HttpStatusCode.BadRequest: {
          const { error } = response.data as RegisterOrUpdateUserResponseError;
          if (error) {
            throw new Error(BackendApiError.AlreadySignedUpEmailToRegisterUser);
          } else {
            throw new Error(
              `[BackendApiContext/registerUser] This error should not occur (${response.status}): ${response.data.detail}`
            );
          }
        }

        case HttpStatusCode.Unauthorized: {
          const { detail } = response.data as RegisterOrUpdateUserResponseError;
          if (detail) {
            throw new Error(BackendApiError.InvalidEmailOrTokenToRegisterUser);
          } else {
            throw new Error(
              `[BackendApiContext/registerUser] This error should not occur (${response.status}): ${response.data.detail}`
            );
          }
        }

        default:
          throw new Error(
            `[BackendApiContext/registerUser] Unhandled response: ${response.status} ${response.data.detail}`
          );
      }
    },
    []
  );

  const updateUser = useCallback(
    async (
      attribute: 'password' | 'email' | 'organization',
      value: string,
      unauth?: {
        temporaryToken: string | undefined;
        email: string | undefined;
      }
    ) => {
      let data;
      switch (attribute) {
        case 'email':
          data = { [attribute]: value };
          break;
        case 'organization':
          data = { [attribute]: value };
          break;
        case 'password':
          if (unauth) {
            data = {
              password: value,
              email: unauth.email,
              token: unauth.temporaryToken
            };
          } else {
            data = { [attribute]: value };
          }
          break;
      }

      const response = await axiosInstance.patch(ApiEndPoint.updateUser + attribute, data);

      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as User;

        case HttpStatusCode.BadRequest:
          throw new Error(BackendApiError.EmailIsAlredayTaken);

        case HttpStatusCode.Unauthorized: {
          if (attribute === 'password' && unauth) {
            const { detail } = response.data as RegisterOrUpdateUserResponseError;
            if (detail) {
              throw new Error(BackendApiError.InvalidEmailOrTokenToUpdateUser);
            }
          }
          throw new Error(
            `[BackendApiContext/updateUser] This error should not occur (${response.status}): ${response.data.detail}`
          );
        }

        default:
          throw new Error(
            `[BackendApiContext/updateUser] Unhandled response: ${response.status} ${response.data.detail}`
          );
      }
    },
    []
  );

  const activateUser = useCallback(async () => {
    const response = await axiosInstance.patch(ApiEndPoint.updateUser + 'isActiveAccount');
    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/activateUser] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/activateUser] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const deleteUser = useCallback(async () => {
    const response = await axiosInstance.delete(ApiEndPoint.user);
    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/deleteUser] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/deleteUser] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // Devices
  const getDeviceInfo = useCallback(async () => {
    const response = await axiosInstance.get(ApiEndPoint.deviceInfo);
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as DeviceInfo[];

      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/getDeviceInfo] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/getDeviceInfo] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // Subjects
  const getSubjectList = useCallback(async (limit: number, offset: number, query?: string) => {
    let baseUrl = `${ApiEndPoint.subjects}?limit=${limit}&offset=${offset}`;
    if (query) {
      baseUrl += `&query=${query}`;
    }
    const response = await axiosInstance.get(baseUrl);
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as SubjectListProps;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/getSubjectList] This error should not occur (${response.status}): ${response.data.detail}`
        );

      case HttpStatusCode.TooManyRequests:
        throw new Error(BackendApiError.APICallsOverFlow);

      default:
        throw new Error(
          `[BackendApiContext/getSubjectList] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const registerSubject = useCallback(async (subject: Omit<Subject, 'id' | 'user'>) => {
    const response = await axiosInstance.post(ApiEndPoint.subjects, {
      number: subject.number,
      name: subject.name,
      birthday: subject.birthday,
      gender: subject.gender
    });

    switch (response.status) {
      case HttpStatusCode.Created:
        return response.data as Subject;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/registerSubject] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/registerSubject] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const updateSubject = useCallback(async (subject: Omit<Subject, 'user'>) => {
    const response = await axiosInstance.patch(`${ApiEndPoint.subjects}${subject.id}/`, {
      number: subject.number,
      name: subject.name,
      birthday: subject.birthday,
      gender: subject.gender
    });

    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data as Subject;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/updateSubject] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/updateSubject] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const deleteSubject = useCallback(async (subjectId: string) => {
    const response = await axiosInstance.delete(`${ApiEndPoint.subjects}${subjectId}/`);
    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/deleteSubject] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/deleteSubject] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const exportSubjectSPPBData = useCallback(
    async (language: string, subjectId: string[] | 'All', excludeId?: string[]) => {
      console.log('[BackendApiContext/exportSubjectSPPBData]', { subjectId, excludeId });
      let queryString = '';
      if (subjectId === 'All') {
        queryString = `subjectId=All`;
        if (excludeId && excludeId.length > 0) {
          excludeId.forEach((id) => {
            queryString += `&subjectId=${id}`;
          });
        }
      } else {
        if (subjectId.length > 0) {
          subjectId.forEach((id, index) => {
            if (index === 0) {
              queryString += `subjectId=${id}`;
            } else {
              queryString += `&subjectId=${id}`;
            }
          });
        }
      }

      console.log(
        `[BackendApiContext/exportSubjectSPPBData], ${ApiEndPoint.exportSubjectData}?${queryString}&lang=${language}`
      );
      const response = await axiosInstance.get(
        `${ApiEndPoint.exportSubjectData}?${queryString}&lang=${language}`,
        {
          responseType: 'blob'
        }
      );

      console.log('[BackendApiContext/exportSubjectSPPBData] response is', response);

      switch (response.status) {
        case HttpStatusCode.OK:
          const filename = response.headers['content-disposition'].split('filename=')[1];
          console.log('[BackendApiContext/exportSubjectSPPBData] Export success', response);
          return { fileData: response.data, fileName: filename };
        case HttpStatusCode.BadRequest:
          console.error(
            `[BackendApiContext/exportSubjectSPPBData] Error occured: (${response.status}): ${response.data.detail}`
          );
          throw new Error(BackendApiError.ExportSubjectDataBadRequest);
        case HttpStatusCode.Unauthorized:
          console.error(
            `[BackendApiContext/exportSubjectSPPBData] Error occured: (${response.status}): ${response.data.detail}`
          );
          throw new Error(BackendApiError.ExportSubjectDataUnauthorized);
        case HttpStatusCode.NotFound:
          console.error(
            `[BackendApiContext/exportSubjectSPPBData] Error occured: (${response.status}): ${response.data.detail}`
          );
          throw new Error(BackendApiError.ExportSubjectDataNotFound);
        default:
          throw new Error(
            `[BackendApiContext/exportSubjectSPPBData] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  // Exams
  const getExamResults = useCallback(
    async (
      examType: ExamTypeExtended,
      limit: number,
      offset: number,
      ordering: Ordering,
      startDate?: string,
      endDate?: string,
      searchQuery?: string,
      subjectPk?: string,
      isCountLimit?: boolean
    ) => {
      let baseUrl = `${ApiEndPoint.exams}?exam=${examType}&limit=${limit}&offset=${offset}&ordering=${ordering}`;
      if (startDate) {
        baseUrl += `&startDate=${startDate}`;
      }
      if (endDate) {
        baseUrl += `&endDate=${endDate}`;
      }
      if (searchQuery) {
        baseUrl += `&searchQuery=${searchQuery}`;
      }
      if (subjectPk) {
        baseUrl += `&subjectPk=${subjectPk}`;
      }
      if (isCountLimit === true) {
        baseUrl += `&isCountLimit=True`;
      } else if (isCountLimit === false) {
        baseUrl += `&isCountLimit=False`;
      }

      const response = await axiosInstance.get(baseUrl);

      switch (response.status) {
        case HttpStatusCode.OK:
          if (response.data) {
            return response.data as ExamResultListProps;
          } else {
            throw new Error(
              `[BackendApiContext/getExamResults] This error should not occur (${response.status}): Undefined data in response`
            );
          }
        case HttpStatusCode.BadRequest:
          throw new Error(BackendApiError.BadRequestError);
        case HttpStatusCode.Unauthorized:
          throw new Error(
            `[BackendApiContext/getExamResults] This error should not occur (${response.status}): ${response.data.detail}`
          );
        case HttpStatusCode.TooManyRequests:
          throw new Error(BackendApiError.APICallsOverFlow);

        default:
          throw new Error(
            `[BackendApiContext/getExamResults] Unhandled response: ${response.status} ${response.data.detail}`
          );
      }
    },
    []
  );
  const getSingleExamResult = useCallback(async (examId: number, examType: ExamType) => {
    const response = await axiosInstance.get(
      `${ApiEndPoint.exams}?examPk=${examId}&exam=${examType}`
    );
    console.log('[BackendApiContext/getSingleExamResult] response success');
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data.results[0] as ExamResultProps;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.NotFound:
        throw new Error(
          `[BackendApiContext/getSingleExamResult] This error should not occur (${response.status}): ${response.data.detail}`
        );
      default:
        throw new Error(
          `[BackendApiContext/getSingleExamResult] Unhandled response: (${response.status}): ${response.data.detail}`
        );
    }
  }, []);

  const getExamResultsInHistory = useCallback(
    async (
      examType: ExamType,
      subjectPk: string,
      examPk?: string,
      index?: string,
      isCountLimit?: boolean,
      limit?: number
    ) => {
      let queryString = `exam=${examType}&subjectPk=${subjectPk}`;

      if (examPk) {
        queryString += `&examPk=${examPk}`;
      } else if (index || index === '0') {
        queryString += `&index=${index}`;
      }

      if (isCountLimit === true) {
        queryString += `&isCountLimit=True`;
      } else if (isCountLimit === false) {
        queryString += `&isCountLimit=False`;
      }

      if (limit) {
        queryString += `&limit=${limit}`;
      }

      const response = await axiosInstance.get(`${ApiEndPoint.examsInHistory}?${queryString}`);

      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as ExamResultListProps;
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/getExamResultsInHistory] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/getExamResultsInHistory] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const getExamSensorData = useCallback(
    async (examId: number, examType: Omit<ExamType, 'AverageGaitSpeed'>) => {
      const response = await axiosInstance.get(`${ApiEndPoint.exams}${examId}/?exam=${examType}`);
      console.log('[BackendApiContext/getExamSensorData] response success');

      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as SensorRawData;
        case HttpStatusCode.BadRequest:
          return {} as SensorRawData;
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/getExamSensorData] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/getExamSensorData] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const removeExamResult = useCallback(async (query: Record<ExamType, number[]>) => {
    console.log('[BackendApiContext/removeExamResult Query] :', query);

    const data: Record<string, Record<'id', number[]>> = {};
    SupportedExamList.forEach((examType) => {
      if (query[examType].length > 0) {
        data[examType] = { id: query[examType] };
      }
    });
    console.log('[BackendApiContext/removeExamResult Parsed Query Data] :', data);

    const response = await axiosInstance.delete(ApiEndPoint.exams, { data });
    console.log('[BackendApiContext/Response]', response);

    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.NotFound:
        throw new Error(
          `[BackendApiContext/removeExamResult] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/removeExamResult] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const updateExamNote = useCallback(async (examType: ExamType, examPk: number, note: string) => {
    console.log('[BackendApiContext/updateExamNote querys]', examType, examPk, note);

    const response = await axiosInstance.patch(
      `${ApiEndPoint.exams}?exam=${examType}&examPk=${examPk}`,
      { note }
    );

    console.log('[BackendApiContext/updateExamNote Response]', response);
    switch (response.status) {
      case HttpStatusCode.OK:
        return;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/updateExamNote] This error should not occur (${response.status}): ${response.data.detail}`
        );

      default:
        throw new Error(
          `[BackendApiContext/updateExamNote] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  // Calculation For SitToStand Count Index Array
  const calculateSitToStandCountIndex = useCallback(
    async (
      sitToStandSetting: ExamSettingsSitToStand,
      weightArray: string[],
      distanceArray: string[]
    ) => {
      const response = await axiosInstance.post(ApiEndPoint.calculateForSitToStand, {
        sitToStandSetting,
        weightArray,
        distanceArray
      });

      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as number[];
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
          throw new Error(
            `[BackendApiContext/calculateSitToStandCountIndex] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/calculateSitToStandCountIndex] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const getCSVExamFileExport = useCallback(
    async (examType: Omit<ExamType, 'AverageGaitSpeed'>, examPk: number[]) => {
      console.log('[BackendApiContext/getCSVExamFileExport]', { examType, examPk });
      let queryString = `exam=${examType}`;
      if (examPk.length > 0) {
        examPk.forEach((pk) => {
          queryString += `&examPk=${pk}`;
        });
      }
      console.log(
        `[BackendApiContext/getCSVExamFileExport], ${ApiEndPoint.examsFileExport}?${queryString}`
      );
      const response = await axiosInstance.get(`${ApiEndPoint.examsFileExport}?${queryString}`, {
        responseType: 'blob'
      });
      // const response = await axiosInstance.get(`${ApiEndPoint.examsFileExport}?${queryString}`);
      console.log('[BackendApiContext/getCSVExamFileExport] response is', response);

      switch (response.status) {
        case HttpStatusCode.OK:
          const filename = response.headers['content-disposition'].split('filename=')[1];
          const decodedFileName = base64.decode(filename);
          console.log('[BackendApiContext/getCSVExamFileExport] Export success', response);
          return { fileData: response.data, fileName: decodedFileName };
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/getCSVExamFileExport] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/getCSVExamFileExport] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const getQRData = useCallback(async (examId: number): Promise<QRDataResponse> => {
    let baseQuery = `${ApiEndPoint.qrData}?examId=${examId}`;
    const response = await axiosInstance.get(baseQuery);
    switch (response.status) {
      case HttpStatusCode.OK:
        return response.data;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.NotFound:
        throw new Error(
          `[BackendApiContext/getQualityControlData] This error should not occur (${response.status}): ${response.data.detail}`
        );
      default:
        throw new Error(
          `[BackendApiContext/getQualityControlData] Unhandled response: (${response.status}): ${response.data.detail}`
        );
    }
  }, []);

  const getAdminDataReport = useCallback(
    async (limit: number, offset: number, searchQuery?: string) => {
      let baseQuery = `${ApiEndPoint.reportAdminData}?limit=${limit}&offset=${offset}`;
      if (searchQuery) {
        baseQuery += `&searchQuery=${searchQuery}`;
      }
      const response = await axiosInstance.get(baseQuery);
      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as AdminReportListProps;
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/getAdminDataReport] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/getAdminDataReport] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const exportAdminData = useCallback(async (userId: string[] | 'All', excludeId?: string[]) => {
    console.log('[BackendApiContext/exportAdminData]', { userId, excludeId });
    let queryString = '';
    if (userId === 'All') {
      queryString = `UserId=All`;
      if (excludeId && excludeId.length > 0) {
        excludeId.forEach((id) => {
          queryString += `&UserId=${id}`;
        });
      }
    } else {
      if (userId.length > 0) {
        userId.forEach((id, index) => {
          if (index === 0) {
            queryString += `UserId=${id}`;
          } else {
            queryString += `&UserId=${id}`;
          }
        });
      }
    }

    console.log(
      `[BackendApiContext/exportAdminData], ${ApiEndPoint.exportAdminData}?${queryString}`
    );
    const response = await axiosInstance.get(`${ApiEndPoint.exportAdminData}?${queryString}`, {
      responseType: 'blob'
    });
    // const response = await axiosInstance.get(`${ApiEndPoint.examsFileExport}?${queryString}`);

    switch (response.status) {
      case HttpStatusCode.OK:
        const filename = response.headers['content-disposition'].split('filename=')[1];
        const decodedFileName = base64.decode(filename);
        console.log('[BackendApiContext/exportAdminData] Export success', response);
        return { fileData: response.data, fileName: decodedFileName };
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.NotFound:
        throw new Error(
          `[BackendApiContext/exportAdminData] This error should not occur (${response.status}): ${response.data.detail}`
        );
      default:
        throw new Error(
          `[BackendApiContext/exportAdminData] Unhandled response: (${response.status}): ${response.data.detail}`
        );
    }
  }, []);

  const exportAdminMainData = useCallback(
    async (userId: string[] | 'All', excludeId?: string[]) => {
      console.log('[BackendApiContext/exportAdminData]', { userId, excludeId });
      let queryString = '';
      if (userId === 'All') {
        queryString = `UserId=All`;
        if (excludeId && excludeId.length > 0) {
          excludeId.forEach((id) => {
            queryString += `&UserId=${id}`;
          });
        }
      } else {
        if (userId.length > 0) {
          userId.forEach((id, index) => {
            if (index === 0) {
              queryString += `UserId=${id}`;
            } else {
              queryString += `&UserId=${id}`;
            }
          });
        }
      }

      console.log(
        `[BackendApiContext/exportAdminMainData], ${ApiEndPoint.exportAdminMainData}?${queryString}`
      );
      const response = await axiosInstance.get(
        `${ApiEndPoint.exportAdminMainData}?${queryString}`,
        {
          responseType: 'blob'
        }
      );
      // const response = await axiosInstance.get(`${ApiEndPoint.examsFileExport}?${queryString}`);

      switch (response.status) {
        case HttpStatusCode.OK:
          const filename = response.headers['content-disposition'].split('filename=')[1];
          const decodedFileName = base64.decode(filename);
          console.log('[BackendApiContext/exportAdminMainData] Export success', response);
          return { fileData: response.data, fileName: decodedFileName };
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/exportAdminMainData] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/exportAdminMainData] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const getLicenseManagementData = useCallback(
    async (limit: number, offset: number, searchQuery?: string) => {
      let baseQuery = `${ApiEndPoint.licenseManagement}?limit=${limit}&offset=${offset}`;
      if (searchQuery) {
        baseQuery += `&searchQuery=${searchQuery}`;
      }
      const response = await axiosInstance.get(baseQuery);
      switch (response.status) {
        case HttpStatusCode.OK:
          return response.data as LicenseManagementResponseType;
        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.NotFound:
          throw new Error(
            `[BackendApiContext/getLicenseManagementData] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/getLicenseManagementData] Unhandled response: (${response.status}): ${response.data.detail}`
          );
      }
    },
    []
  );

  const postLicenseManagementData = useCallback(
    async ({ email, organization, division, device }: LicenseManagementBodyType) => {
      // console.log(
      //   '[BackendApiContext/postLicenseManagementData body]',
      //   email,
      //   organization,
      //   division,
      //   device
      // );

      const response = await axiosInstance.post(`${ApiEndPoint.licenseManagement}`, {
        email,
        organization,
        division,
        device
      });

      console.log('[BackendApiContext/postLicenseManagementData Response]', response);
      switch (response.status) {
        case HttpStatusCode.OK:
        case HttpStatusCode.Created:
          return;
        case HttpStatusCode.BadRequest:
          if (response?.data?.email?.email) {
            throw new Error(response.data.email.email[0]);
          } else if (response?.data?.device?.device) {
            throw new Error(response.data.device.device[0]);
          } else {
            throw new Error(
              `[BackendApiContext/postLicenseManagementData] This error should not occur (${response.status}): ${response.data.detail}`
            );
          }
        case HttpStatusCode.Unauthorized:
          throw new Error(
            `[BackendApiContext/postLicenseManagementData] This error should not occur (${response.status}): ${response.data.detail}`
          );
        default:
          throw new Error(
            `[BackendApiContext/postLicenseManagementData] Unhandled response: ${response.status} ${response.data.detail}`
          );
      }
    },
    []
  );

  const getQualityControlData = useCallback(async () => {
    let baseQuery = `${ApiEndPoint.qualityControl}`;
    const response = await axiosInstance.get(baseQuery);
    switch (response.status) {
      case HttpStatusCode.OK:
        return response as QualityControlResponseType;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.NotFound:
        throw new Error(
          `[BackendApiContext/getQualityControlData] This error should not occur (${response.status}): ${response.data.detail}`
        );
      default:
        throw new Error(
          `[BackendApiContext/getQualityControlData] Unhandled response: (${response.status}): ${response.data.detail}`
        );
    }
  }, []);

  const postQualityControlData = useCallback(async (value: QualityControlPostDataProps) => {
    // console.log('[BackendApiContext/postQualityControlData body]', value);

    const response = await axiosInstance.post(`${ApiEndPoint.qualityControl}`, value);

    console.log('[BackendApiContext/postQualityControlData Response]', response);
    switch (response.status) {
      case HttpStatusCode.OK:
      case HttpStatusCode.Created:
        return;
      case HttpStatusCode.BadRequest:
        if (response?.data[0]?.serial_number) {
          throw new Error(response.data[0].serial_number[0]);
        } else if (response?.data[0]?.release_date) {
          throw new Error(response.data[0].release_date[0]);
        } else {
          throw new Error(
            `[BackendApiContext/postQualityControlData] This error should not occur (${response.status}): ${response.data.detail}`
          );
        }
      case HttpStatusCode.Unauthorized:
        throw new Error(
          `[BackendApiContext/postQualityControlData] This error should not occur (${response.status}): ${response.data.detail}`
        );
      default:
        throw new Error(
          `[BackendApiContext/postQualityControlData] Unhandled response: ${response.status} ${response.data.detail}`
        );
    }
  }, []);

  const deleteQualityControlData = useCallback(
    async (objectId: string[] | 'All', excludeId?: string[]) => {
      console.log('[BackendApiContext/exportAdminData]', { objectId, excludeId });
      let queryString = '';
      if (objectId === 'All') {
        queryString = `ObjectId=All`;
        if (excludeId && excludeId.length > 0) {
          excludeId.forEach((id) => {
            queryString += `&ObjectId=${id}`;
          });
        }
      } else {
        if (objectId.length > 0) {
          objectId.forEach((id, index) => {
            if (index === 0) {
              queryString += `ObjectId=${id}`;
            } else {
              queryString += `&ObjectId=${id}`;
            }
          });
        }
      }

      const response = await axiosInstance.delete(`${ApiEndPoint.qualityControl}?${queryString}`);

      console.log('[BackendApiContext/deleteQualityControlData Response]', response);
      switch (response.status) {
        case HttpStatusCode.OK:
        case HttpStatusCode.NoContent:
          return;

        case HttpStatusCode.BadRequest:
        case HttpStatusCode.Unauthorized:
          throw new Error(
            `[BackendApiContext/deleteQualityControlData] This error should not occur (${response.status}): ${response.data.detail}`
          );

        default:
          throw new Error(
            `[BackendApiContext/deleteQualityControlData] Unhandled response: ${response.status} ${response.data.detail}`
          );
      }
    },
    []
  );

  return (
    <BackendApiContext.Provider
      value={{
        // ...state,
        // Server health
        getServerHealth: getServerHealth,
        // Token
        login: login,
        logout: logout,
        // Server time
        getServerTime: getServerTime,
        // Email authentication
        sendAuthLink: sendAuthLink,
        // Verification
        verifySerialNumber: verifySerialNumber,
        verifyEmail: verifyEmail,
        verifyPassword: verifyPassword,
        verifyNumber: verifyNumber,
        getLastLogin: getLastLogin,
        // User
        getUser: getUser,
        registerUser: registerUser,
        updateUser: updateUser,
        activateUser: activateUser,
        deleteUser: deleteUser,
        // Devices
        getDeviceInfo: getDeviceInfo,
        // Subjects
        getSubjectList: getSubjectList,
        registerSubject: registerSubject,
        updateSubject: updateSubject,
        deleteSubject: deleteSubject,
        exportSubjectSPPBData: exportSubjectSPPBData,
        // Exams
        getExamResults: getExamResults,
        getSingleExamResult: getSingleExamResult,
        getExamResultsInHistory: getExamResultsInHistory,
        getExamSensorData: getExamSensorData,
        removeExamResult: removeExamResult,
        updateExamNote: updateExamNote,
        // CSV Export
        getCSVExamFileExport: getCSVExamFileExport,
        // QR data
        getQRData: getQRData,
        // ETC
        calculateSitToStandCountIndex: calculateSitToStandCountIndex,
        // Admin Only
        getAdminDataReport: getAdminDataReport,
        exportAdminData: exportAdminData,
        exportAdminMainData: exportAdminMainData,
        getLicenseManagementData: getLicenseManagementData,
        postLicenseManagementData: postLicenseManagementData,
        getQualityControlData: getQualityControlData,
        postQualityControlData: postQualityControlData,
        deleteQualityControlData: deleteQualityControlData
      }}
    >
      {children}
    </BackendApiContext.Provider>
  );
}

export { BackendApiContext, BackendApiProvider };

//Calculation For PDF Print and download
export const calculatePhysiologicalIndex = async (
  age: number,
  staticBalanceScore: number,
  sitToStandScore: number,
  gaitSpeedScore: number
) => {
  const response = await axiosInstance.post(ApiEndPoint.calculateForPrint, {
    age: age,
    staticBalanceScore: staticBalanceScore,
    sitToStandScore: sitToStandScore,
    gaitSpeedScore: gaitSpeedScore
  });
  switch (response.status) {
    case HttpStatusCode.OK:
      return response.data as calculatePhysiologicalIndexProps;
    case HttpStatusCode.BadRequest:
    case HttpStatusCode.Unauthorized:
      throw new Error(
        `[BackendApiContext/calculatePhysiologicalIndex] This error should not occur (${response.status}): ${response.data.detail}`
      );
    default:
      throw new Error(
        `[BackendApiContext/calculatePhysiologicalIndex] Unhandled response: (${response.status}): ${response.data.detail}`
      );
  }
};
