import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemText, Stack } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import MenuPopover from '../../components/MenuPopover';
import { SmallIcon } from '../../styles/icon';
import { SubBodyText } from '../../styles/typography';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang, translate } = useLocales();

  return (
    <>
      <Stack
        ref={anchorRef}
        onClick={() => setOpen(true)}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{
          padding: 1,
          cursor: 'pointer'
          // ...(open && { bgcolor: 'action.selected' })
        }}
      >
        <SmallIcon src={'/static/icons/ic_language.svg'} alt={currentLang.label} />
        <SubBodyText style={{ fontWeight: 700 }}>
          {currentLang.value === 'ko'
            ? translate('LanguagePopoverKR')
            : currentLang.value === 'ja'
            ? translate('LanguagePopoverJA')
            : translate('LanguagePopoverEN')}
        </SubBodyText>
      </Stack>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                setOpen(false);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.value === 'ko'
                  ? translate('LanguagePopoverKR')
                  : option.value === 'ja'
                  ? translate('LanguagePopoverJA')
                  : translate('LanguagePopoverEN')}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
