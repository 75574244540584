export const ApiEndPoint = {
  // Health
  health: 'health-check/',
  // Token
  issueToken: 'api/v1/token/',
  refreshTokenPair: 'api/v1/token/refresh/',
  verifyToken: 'api/v1/token/verify/',
  discardToken: 'api/v1/token/blacklist/',
  // Server time
  ping: 'api/v1/ping/',
  // Email authentication
  sendAuthLink: 'api/v1/email-auth/',
  // Verification
  verify: 'api/v1/verification/?attr=',
  // User
  user: 'api/v1/user/',
  updateUser: 'api/v1/user/?attr=',
  // Devices
  deviceInfo: 'api/v1/devices/',
  // Settings
  examSettings: 'api/v1/settings/',
  // Subjects
  subjects: 'api/v1/subjects/',
  exportSubjectData: 'api/v1/web-management/export/subjects/exams/',
  // Exams
  exams: 'api/v1/exams/',
  examsInHistory: 'api/v1/web-management/exams/',
  examsFileExport: 'api/v1/web-management/exams/sensor-data/',
  //Calcuation for PDF Print
  calculateForPrint: 'api/v1/calculator/',
  //Calcuation for sitToStand count point
  calculateForSitToStand: 'api/v1/calculator/sit-to-stand-crossing/',
  // QR data
  qrData: 'api/v1/qr-data/',
  //Admin Only
  reportAdminData: 'api/v1/web-management/report/admin/',
  exportAdminData: 'api/v1/web-management/export/admin/',
  exportAdminMainData: 'api/v1/web-management/export/admin/main/',
  licenseManagement: 'api/v1/web-management/license-management/staff/',
  qualityControl: 'api/v1/web-management/device/staff/'
} as const;
