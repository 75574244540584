import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthHeader from '../layouts/AuthHeader';
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  const isDashboard = pathname?.includes('/dashboard');

  console.log('[routes index/ Loadable] present pathName :', pathname);

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    { path: 'guidelines', element: <Guidelines /> },
    {
      path: 'auth',
      children: [
        { path: 'sign-up', element: <SignUp /> },
        { path: 'find-email', element: <FindEmail /> },
        {
          path: 'reset-password',
          element: <ResetPassword />,
          children: [{ path: ':authcode', element: <ResetPassword /> }]
        },
        { path: 'inactive-user', element: <InActiveUser /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-use', element: <TermsOfUse /> }
      ]
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/dashboard/overview" replace /> },
        { path: 'overview', element: <OverView /> },
        //exams
        { path: 'sppb-exams', element: <SPPBExamListMain /> },
        { path: 'sppb-exams/:id', element: <SPPBExamDetailResult /> },
        { path: 'gaitspeed-exams', element: <GaitSpeedExamListMain /> },
        { path: 'gaitspeed-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'sittostand-exams', element: <SitToStandExamListMain /> },
        { path: 'sittostand-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'staticbalance-exams', element: <StaticBalanceExamListMain /> },
        { path: 'staticbalance-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'averagegaitspeed-exams', element: <AverageGaitSpeedExamListMain /> },
        { path: 'averagegaitspeed-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'onelegstanding-exams', element: <OneLegStandingExamListMain /> },
        { path: 'onelegstanding-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'tug-exams', element: <TUGExamListMain /> },
        { path: 'tug-exams/:id', element: <ExamDetailResultNotSPPB /> },
        { path: 'handgripstrength-exams', element: <HandGripStrengthExamListMain /> },
        { path: 'handgripstrength-exams/:id', element: <ExamDetailResultNotSPPB /> },
        //management
        { path: 'useraccount-setting', element: <UserAccountSettingMain /> },
        { path: 'email-change', element: <EmailChangeTemp /> },
        { path: 'password-change', element: <PasswordChangeTemp /> },
        { path: 'subject-management', element: <SubjectManagement /> },
        { path: 'customer-service', element: <CustomerService /> },
        { path: 'admin', element: <AdminDashboard /> },
        { path: 'admin/report', element: <ReportDashboard /> },
        { path: 'admin/quality-control', element: <QualityControl /> },
        { path: 'admin/license-management', element: <LicenseManagement /> }
      ]
    },

    {
      path: '*',
      element: <AuthHeader />,
      children: [
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '401', element: <Page401 /> }
      ]
    }
  ]);
}

const Guidelines = Loadable(lazy(() => import('../pages/Guidelines')));
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/login/Login')));
const FindEmail = Loadable(lazy(() => import('../pages/authentication/findEmail/FindEmail')));
const SignUp = Loadable(lazy(() => import('../pages/authentication/signUp/SignUp')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/resetPassword/ResetPassword'))
);
const InActiveUser = Loadable(
  lazy(() => import('../pages/authentication/inactiveUser/InActiveUser'))
);
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsOfUse = Loadable(lazy(() => import('../pages/TermOfUse')));

// Dashboard
const OverView = Loadable(lazy(() => import('../pages/dashboard/exams/overview/Overview')));
const AverageGaitSpeedExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/averageGaitSpeedExam/AverageGaitSpeedExamListMain'))
);
const GaitSpeedExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/gaitSpeedExam/GaitSpeedExamListMain'))
);
const SitToStandExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/sitToStandExam/SitToStandExamListMain'))
);
const SPPBExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/sppbExam/SPPBExamListMain'))
);
const StaticBalanceExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/staticBalanceExam/StaticBalanceExamListMain'))
);
const OneLegStandingExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/oneLegStandingExam/OneLegStandingExamListMain'))
);
const TUGExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/tugExam/TUGExamListMain'))
);
const HandGripStrengthExamListMain = Loadable(
  lazy(() => import('../pages/dashboard/exams/handgripStrength/HandgripStrengthListMain'))
);
const ExamDetailResultNotSPPB = Loadable(
  lazy(
    () => import('../pages/dashboard/components/ExamDetailResult/ExamDetailResultNotSPPBContainer')
  )
);
const SPPBExamDetailResult = Loadable(
  lazy(() => import('../pages/dashboard/exams/sppbExam/SPPBExamDetailResultContainer'))
);

// Management
const UserAccountSettingMain = Loadable(
  lazy(() => import('../pages/dashboard/management/userAccountSetting/UserAccountSettingMain'))
);
const EmailChangeTemp = Loadable(
  lazy(() => import('../pages/dashboard/management/userAccountSetting/EmailChangeTemp'))
);
const PasswordChangeTemp = Loadable(
  lazy(() => import('../pages/dashboard/management/userAccountSetting/PasswordChangeTemp'))
);
const SubjectManagement = Loadable(
  lazy(() => import('../pages/dashboard/management/subjectManagemnet/SubjectManagement'))
);
const CustomerService = Loadable(
  lazy(() => import('../pages/dashboard/management/customerService/CustomerService'))
);
const AdminDashboard = Loadable(
  lazy(() => import('../pages/dashboard/management/admin/AdminDashboard'))
);
const ReportDashboard = Loadable(
  lazy(() => import('../pages/dashboard/management/admin/ReportDashboard'))
);
const QualityControl = Loadable(
  lazy(() => import('../pages/dashboard/management/admin/QualityControl'))
);
const LicenseManagement = Loadable(
  lazy(() => import('../pages/dashboard/management/admin/LicenseManagement'))
);

// Main
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page401 = Loadable(lazy(() => import('../pages/Page401')));
