// import { useMemo } from 'react';
// hooks

import { Box } from '@mui/material';

export default function MyAvatar() {
  return (
    <Box sx={{ my: '32px' }}>
      <img
        style={{ width: '56px', height: '56px' }}
        src={'/static/user_avatar.png'}
        alt={'user_avatar'}
      />
    </Box>
  );
}
